import { usePermitHoldersContext } from "components/permitHolders/Creation/Provider";
import useCountries from "hooks/useCountries";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Yup from "yup.js";

const usePermitHoldersFormValidation = (entityId) => {
  const { t } = useTranslation();
  const { countryCodesMapping } = useCountries({ entityId });
  const { selectedPermitType } = usePermitHoldersContext();
  const { shouldRegisterAddressStreet } = selectedPermitType.permitType;

  const validationSchema = useMemo(
    () =>
      Yup.object({
        partyTypeId: Yup.number().required(t("10271") /* O campo é obrigatório */),
        name: Yup.string().required(t("10271") /* O campo é obrigatório */),
        countryId: Yup.number().required(t("10271") /* O campo é obrigatório */),
        taxpayerIdentificationNumber: Yup.string()
          .required(t("10271") /* O campo é obrigatório */)
          .vat(
            t("9977") /* O campo é inválido para o país selecionado */,
            countryCodesMapping
          ),
        economicActivityCode: Yup.number()
          .economicActivityCode(
            t("10306") /* Deve ser um valor numérico positivo com 5 caracteres */
          )
          .requiredIfCompany(t("10271") /* O campo é obrigatório */),
        commercialName: Yup.string().requiredIfCompany(
          t("10271") /* O campo é obrigatório */
        ),
        address: shouldRegisterAddressStreet
          ? Yup.string()
          : Yup.string().required(t("10271") /* O campo é obrigatório */),
        zipCode: Yup.string()
          .required(t("10271") /* O campo é obrigatório */)
          .zipCode(
            t("9977") /* O campo é inválido para o país selecionado */,
            countryCodesMapping
          ),
        districtId: Yup.number().required(t("10271") /* O campo é obrigatório */),
        locality: shouldRegisterAddressStreet
          ? Yup.string()
          : Yup.string().required(t("10271") /* O campo é obrigatório */),
        email: Yup.string()
          .required(t("10271") /* O campo é obrigatório */)
          .email(t("7469") /* O campo é inválido */),
        phone: Yup.string().phone(
          t("7894") /* O campo deverá ser válido */,
          Yup.ref("countryId")
        ),
        mobilePhone: Yup.string()
          .required(t("10271") /* O campo é obrigatório */)
          .phone(t("7894") /* O campo deverá ser válido */, Yup.ref("countryId")),
        addressStreetId: shouldRegisterAddressStreet
          ? Yup.number().required(t("10271") /* O campo é obrigatório */)
          : Yup.number(),
        localityId: shouldRegisterAddressStreet
          ? Yup.number().required(t("10271") /* O campo é obrigatório */)
          : Yup.number(),
      }),
    []
  );

  return {
    validationSchema,
  };
};

export default usePermitHoldersFormValidation;
