/* eslint-disable react/no-array-index-key */
import React from "react";
import FlexBox from "components/FlexBox";
import { Button } from "iparque-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AdvanceButton from "../../../components/AdvanceButton";
import VehicleForm from "../../components/Vehicle/Form";
import useVehiclesStep from "./useVehiclesStep";

const VehiclesStep = () => {
  const { t } = useTranslation();
  const {
    vehicles,
    onAdvance,
    addVehicle,
    editVehicle,
    makeEditable,
    removeVehicle,
    isEditing,
  } = useVehiclesStep();

  return (
    <FlexBox className="fill" flexDirection="column" justifyContent="spaceBetween">
      <div>
        {vehicles.map((vehicle, index) => (
          <VehicleForm
            key={index}
            position={index}
            editVehicle={editVehicle}
            makeEditable={makeEditable}
            removeVehicle={removeVehicle}
            canRemove={index > 0 && index === vehicles.length - 1}
            vehicle={vehicle}
          />
        ))}
        {!isEditing && (
          <div className="mt-20">
            <StyledButton variant="link" color="quintenary" onClick={addVehicle}>
              {t("10259") /* Adicionar veículo  */}
            </StyledButton>
          </div>
        )}
      </div>
      <AdvanceButton type="button" onClick={onAdvance} />
    </FlexBox>
  );
};

export default VehiclesStep;

const StyledButton = styled(Button)`
  padding: 0;
  text-decoration: underline;
`;
