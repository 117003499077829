import { Heading } from "iparque-components/dist/components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const PermitTypeTitle = ({ permitType }) => {
  const { t } = useTranslation();

  return (
    <Title variant="h7" color="quintenary" className="mb-25">
      {`${t("1848") /* Dístico */} - ${permitType}`}
    </Title>
  );
};

export default PermitTypeTitle;

PermitTypeTitle.propTypes = {
  permitType: PropTypes.string.isRequired,
};

const Title = styled(Heading)`
  font-size: 18px;
  font-weight: ${({ theme }) => theme.typography.weight.bold};
`;
