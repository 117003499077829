import GenericDocumentsStep from "components/permitHolders/components/DocumentsStep";
import { useStepContext } from "domain/public/components/StepsProvider/Step";
import React from "react";
import { usePermitHolderRenewalContext } from "../../Provider";
import { actionTypes } from "../../store/actions";

const DocumentsStep = () => {
  const { dispatch, documents: submittedDocuments } = usePermitHolderRenewalContext();
  const { onAdvance, documents } = useStepContext();

  const onSubmitDocument = (doc) =>
    dispatch({ type: actionTypes.ADD_DOCUMENT, payload: doc });

  const onRemoveDocument = (id) =>
    dispatch({ type: actionTypes.REMOVE_DOCUMENT, payload: id });

  return (
    <GenericDocumentsStep
      onSubmitDocument={onSubmitDocument}
      onRemoveDocument={onRemoveDocument}
      submittedDocuments={submittedDocuments}
      documents={documents}
      onStepAdvance={onAdvance}
    />
  );
};

export default DocumentsStep;
