import StepsProvider from "domain/public/components/StepsProvider";
import React from "react";
import PermitHolderRenewalProvider from "./Provider";
import PermitHolderRenewalSteps from "./steps";

const PermitHolderRenewalSubmission = () => {
  return (
    <StepsProvider>
      <PermitHolderRenewalProvider>
        <PermitHolderRenewalSteps />
      </PermitHolderRenewalProvider>
    </StepsProvider>
  );
};

export default PermitHolderRenewalSubmission;
