import { ReactComponent as IparqueDriver } from "assets/images/iparqueDriver.svg";
import Link from "components/Link";
import { Text } from "iparque-components";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import RegistryForm from "./RegistryForm";

const ConfirmationStatus = () => {
  const { state = {} } = useLocation();
  const { t } = useTranslation();
  const { allowRegistry } = state;

  return (
    <>
      {allowRegistry ? (
        <>
          <Body color="quintenary" className="inline mt-10" variant="body5">
            <Trans
              i18nKey="12814"
              components={{ a: <Link target="_blank" underline="always" href="/" /> }}
            />
            {/* Registe-se no <a>iParque driver</a> para poder fazer a gestão do seu dístico. */}
          </Body>
          <div className="mt-40">
            <CreateAccountText color="quintenary" variant="body5">
              {t("7892") /* Criar conta */}
            </CreateAccountText>
            <IparqueDriver width={180} />
          </div>
          <RegistryForm />
        </>
      ) : (
        <Body color="quintenary" className="mt-10" variant="body5">
          {
            t(
              "12815"
            ) /* Como já tem conta no iParque Driver, irá receber um email para associar o dístico à sua conta. */
          }
        </Body>
      )}
    </>
  );
};

export default ConfirmationStatus;

const Body = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.weight.regular};
`;

const CreateAccountText = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.weight.bold};
`;
