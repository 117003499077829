import { errorToast } from "iparque-components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createClient } from "requests/clients";
import {
  addPermitHolderVehicle,
  associatePermitHolderDriver,
  changePermitHolderState,
  createPermitHolder,
  deletePermitHolder,
  saveDocument,
} from "requests/permitHolders";
import { clientValidationScenarios, partyCreationTypes } from "utils/clients";
import { cleanLicencePlate } from "utils/licencePlate";
import { permitHolderStates } from "utils/permitHolders";
import { userTypes } from "utils/users";
import { usePermitHoldersContext } from "../Provider";

const usePermitHolderCreation = () => {
  const { t } = useTranslation();
  const {
    entity,
    personalData,
    selectedPermitType,
    vehicles,
    documents,
  } = usePermitHoldersContext();

  const create = useCallback(
    async ({ recaptchaToken, driverId, driverHash } = {}) => {
      const clientResponse = await createClient(
        entity.id,
        {
          ...personalData,
          scenario: clientValidationScenarios.permitHolders,
          creationContextTypeId: partyCreationTypes.permitHolders,
          recaptchaToken,
        },
        { autoCloseError: false }
      );

      if (!clientResponse) {
        return null;
      }

      const { clientId } = clientResponse;

      const permitHolderResponse = await createPermitHolder(
        entity.id,
        {
          clientId,
          permitId: selectedPermitType.permitType.id,
          userTypeId: userTypes.client,
          userId: driverId,
        },
        { autoCloseError: false }
      );

      if (!permitHolderResponse) {
        return null;
      }

      const {
        permitHolderId,
        permitHolderToken,
        allowRegistry,
        hasSubmissionRate,
      } = permitHolderResponse;

      if (driverHash) {
        const success = await associatePermitHolderDriver(
          entity.id,
          driverHash,
          permitHolderToken
        );

        if (!success) {
          deletePermitHolder(entity.id, permitHolderToken, {
            userTypeId: userTypes.client,
            userId: driverId,
          });

          return null;
        }
      }

      const promises = [
        ...vehicles.map((vehicle) =>
          addPermitHolderVehicle(
            entity.id,
            {
              driverHash,
              permitHolderId: permitHolderToken,
              data: {
                vehicleRegimeId: vehicle.regime.id,
                licensePlate: cleanLicencePlate(vehicle.licencePlate),
                modelId: vehicle.modelId,
                userTypeId: userTypes.client,
                userId: driverId,
                countryId: vehicle.countryId,
              },
            },
            { autoCloseError: false }
          )
        ),
        ...Object.values(documents).map((doc) =>
          saveDocument(
            entity.id,
            permitHolderToken,
            {
              file: doc.file,
              typeId: doc.id,
              userId: driverId,
            },
            { autoCloseError: false }
          )
        ),
      ];

      if (!hasSubmissionRate) {
        promises.push(
          changePermitHolderState(
            entity.id,
            permitHolderToken,
            {
              permitHolderStateId: permitHolderStates.underAnalysis,
              userTypeId: userTypes.client,
              userId: driverId,
            },
            { autoCloseError: false }
          )
        );
      }

      const results = await Promise.all(promises);

      const areAllRequestsMadeWithSuccess = results.every((result) => result);

      if (!areAllRequestsMadeWithSuccess) {
        deletePermitHolder(entity.id, permitHolderToken, {
          userTypeId: userTypes.client,
          userId: driverId,
        });

        errorToast(
          t("3613") /* Erro */,
          t(
            "10528"
          ) /* Foram obtidos alguns erros ao guardar os dados do dístico, por favor contacta o nosso apoio ao cliente */,
          { autoClose: false }
        );

        return null;
      }

      return { permitHolderId, allowRegistry };
    },
    [entity, personalData, selectedPermitType, documents]
  );

  return create;
};

export default usePermitHolderCreation;
