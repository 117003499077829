import FlexBox from "components/FlexBox";
import AdvanceButton from "components/permitHolders/components/AdvanceButton";
import { useStepContext } from "domain/public/components/StepsProvider/Step";
import useLanguage from "hooks/useLanguage";
import { Heading } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { formatDate } from "utils/dateTime";
import { usePermitHolderRenewalContext } from "../../Provider";

const InfoStep = () => {
  const { t } = useTranslation();
  const { isInitialDataLoading, renewalInfo } = usePermitHolderRenewalContext();
  const language = useLanguage();
  const { onAdvance } = useStepContext();

  return (
    <FlexBox flexDirection="column" justifyContent="spaceBetween" className="fill">
      <div>
        <StyledText variant="h5" color="quintenary">
          {
            t(
              "12532"
            ) /* Para procederes à renovação basta certificares-te que todos os dados estão atualizados para que possas usufruir de estacionamento nas zonas reservadas. */
          }
        </StyledText>
        {!isInitialDataLoading && (
          <StyledText variant="h5" color="quintenary" className="mt-40">
            {
              t("12533", {
                startDate: formatDate(renewalInfo.from, language),
                endDate: formatDate(renewalInfo.until, language),
                interpolation: { escapeValue: false },
              }) /* Podes renovar o teu dístico de {{startDate}} até {{endDate}} */
            }
          </StyledText>
        )}
      </div>
      <StyledButton type="button" onClick={onAdvance}>
        {t("12536") /* Renovar dístico */}
      </StyledButton>
    </FlexBox>
  );
};

export default InfoStep;

const StyledText = styled(Heading)`
  font-size: 20px;
`;

const StyledButton = styled(AdvanceButton)`
  width: 240px;
`;
