import { getTokens } from "../../../../utils/auth";
import AbstractDataSource from "./abstractDataSource";

export default class LocalitiesDataSource extends AbstractDataSource {
  async getAll(entityId, params = {}, useCache = false) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/localities`,
      params,
      { Authorization: getTokens().token },
      useCache
    );
  }
}
