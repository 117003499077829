import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getPermitHolderRenewal } from "requests/permitHolders";
import { usePublicEntityContext } from "../../../Provider";
import reducer from "./store/reducer";
import { actionTypes } from "./store/actions";

const initialState = {
  isInitialDataLoading: true,
  hasError: false,
  renewalInfo: {
    from: undefined,
    until: undefined,
    token: "",
    permitHolderType: {
      designation: "",
      id: undefined,
    },
  },
  documents: {},
};

const Context = createContext({ ...initialState, dispatch: () => {} });

export const usePermitHolderRenewalContext = () => {
  return useContext(Context);
};

const PermitHolderRenewalProvider = ({ children }) => {
  const { t } = useTranslation();
  const { renewalToken } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { entity } = usePublicEntityContext();

  useEffect(() => {
    if (!entity) {
      return;
    }

    (async () => {
      const renewalData = await getPermitHolderRenewal(
        entity.id,
        renewalToken,
        { fillCollections: "permitHolderType" },
        { t }
      );

      const actions = [{ type: actionTypes.IS_INITIAL_DATA_LOADING, payload: false }];

      if (renewalData) {
        actions.push({
          type: actionTypes.SET_RENEWAL_INFO,
          payload: renewalData.items[0],
        });
      } else {
        actions.push({ type: actionTypes.HAS_ERROR, payload: true });
      }

      dispatch(actions);
    })();
  }, [entity]);

  const values = useMemo(() => ({ ...state, dispatch }), [state]);

  return <Context.Provider value={values}>{children}</Context.Provider>;
};

export default PermitHolderRenewalProvider;

PermitHolderRenewalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
