import PropTypes from "prop-types";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import ConfirmationPopUp from "./ConfirmationPopUp";
import AlertPopUp from "./AlertPopUp";

const BackOfficeContext = createContext({
  displayConfirmation: () => {},
  displayAlert: () => {},
});

export const useBackofficeContext = () => {
  return useContext(BackOfficeContext);
};

const BackOfficeProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    display: false,
    title: "",
    message: "",
    onClose: () => {},
  });

  const [confirmation, setConfirmation] = useState({
    display: false,
    title: "",
    message: "",
    onConfirm: () => {},
    onCancel: () => {},
    configs: {},
  });

  const onCloseConfirmation = useCallback(() => {
    setConfirmation({ ...confirmation, display: false });
  }, []);

  const displayConfirmation = useCallback(
    ({ title, message, onConfirm, onCancel, configs }) => {
      setConfirmation({
        display: true,
        title,
        message,
        onConfirm: () => {
          onConfirm();
          onCloseConfirmation();
        },
        onCancel: () => {
          onCancel?.();
          onCloseConfirmation();
        },
        configs,
      });
    },
    []
  );

  const onCloseAlert = useCallback(() => {
    setAlert({ ...alert, display: false });
  }, []);

  const displayAlert = useCallback(({ title, message, onClose }) => {
    setAlert({
      display: true,
      title,
      message,
      onClose: () => {
        onClose?.();
        onCloseAlert();
      },
    });
  }, []);

  const values = useMemo(() => ({ displayConfirmation, displayAlert }), []);

  return (
    <BackOfficeContext.Provider value={values}>
      {confirmation.display && (
        <ConfirmationPopUp
          onCancel={confirmation.onCancel}
          onConfirm={confirmation.onConfirm}
          title={confirmation.title}
          message={confirmation.message}
          configs={confirmation.configs}
        />
      )}
      {alert.display && (
        <AlertPopUp title={alert.title} message={alert.message} onClose={alert.onClose} />
      )}
      {children}
    </BackOfficeContext.Provider>
  );
};

BackOfficeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BackOfficeProvider;
