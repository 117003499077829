import i18n from "i18n";
import LocalitiesDataSource from "lib/clients/iParque/dataSources/localitiesDataSource";
import { errorMessage } from "utils/userMessages";

const localitiesDataSource = new LocalitiesDataSource();

export const getLocalities = async (entityId, params = {}, useCache = false) => {
  try {
    const response = await localitiesDataSource.getAll(entityId, params, useCache);

    return {
      items: response.items,
      totalItems: response.totalItems,
    };
  } catch (error) {
    errorMessage(error, i18n.t("11218") /* Ocorreu um erro ao obter as localidades */);

    return { hasError: true, items: [] };
  }
};
