import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { STEPS } from "../helper";
import { usePermitHoldersContext } from "../Provider";

const useTitle = () => {
  const { currentStep } = usePermitHoldersContext();
  const { t } = useTranslation();

  const title = useMemo(
    () =>
      ({
        [STEPS.cities]: t(
          "12805"
        ) /* Escolha a cidade onde pretende adquirir o dístico */,
        [STEPS.permitTypes]: t("12806") /* Escolha o tipo de dístico */,
        [STEPS.permitDetails]: t("12807") /* Verifique as condições */,
        [STEPS.personalData]: t("12808") /* Preencha os seus dados */,
        [STEPS.documents]: t("12809") /* Adicione os seus documentos */,
        [STEPS.vehicles]: t("12810") /* Adicione os seus veículos */,
        [STEPS.confirmation]: t("12811") /* Verifique os dados */,
      }[currentStep?.index] || ""),
    [currentStep, t]
  );

  return title;
};

export default useTitle;
