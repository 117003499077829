import Error403 from "domain/public/entities/components/errors/403";
import { usePublicEntityContext } from "domain/public/entities/Provider";
import { Link, Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import ConfirmationStatus from "../../components/confirmation/Status";
import Template from "../../components/Template";

const PermitHolderRenewalConfirmation = () => {
  const { t } = useTranslation();
  const { state = {} } = useLocation();
  const { renewalToken } = useParams();
  const { isEntityDataLoading } = usePublicEntityContext();

  const { evidenceUrl, renewalToken: routeRenewalToken } = state;

  if (renewalToken !== routeRenewalToken) {
    return <Error403 />;
  }

  return (
    <Template
      isLoading={isEntityDataLoading}
      title={t("12564") /* Renovação do dístico submetida com sucesso */}
    >
      <Body color="quintenary" variant="body5">
        {t("12565") /* A renovação do teu dístico foi submetida com sucesso. */}
      </Body>
      {evidenceUrl && (
        <Body color="quintenary" variant="body5">
          <span>
            {t("10303") /* Podes descarregar o comprovativo */}{" "}
            <Link underline="always" target="_blank" href={evidenceUrl}>
              {t("6515") /* aqui */}
            </Link>
          </span>
        </Body>
      )}
      <ConfirmationStatus />
    </Template>
  );
};

export default PermitHolderRenewalConfirmation;

const Body = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.weight.bold};
`;
