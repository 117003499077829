import { getTokens } from "../../../../utils/auth";
import AbstractDataSource from "./abstractDataSource";

export default class StreetsDataSource extends AbstractDataSource {
  async getAll(entityId, params = {}, headers = {}, useCache = false) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/streets`,
      params,
      { ...headers, Authorization: getTokens().token },
      useCache
    );
  }

  async getFees(entityId, streetId, params = {}, useCache = false) {
    return this.iParqueClient.GET(
      `v2/entities/${entityId}/streets/${streetId}/fees`,
      params,
      {
        Authorization: getTokens().token,
      },
      useCache
    );
  }

  async getHolidays(entityId, streetId, params = {}, useCache = false) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/streets/${streetId}/holidays`,
      params,
      { Authorization: getTokens().token },
      useCache
    );
  }

  async getAllStreets(entityId, params = {}, useCache = false) {
    return this.iParqueClient.GET(
      `v2/entities/${entityId}/streets`,
      params,
      { Authorization: getTokens().token },
      useCache
    );
  }
}
