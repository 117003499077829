import PropTypes from "prop-types";
import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { actionTypes } from "./store/actions";
import reducer from "./store/reducer";

const initialState = {
  currentStep: { index: undefined, state: {} },
};

const StepsContext = createContext(initialState);

export const useStepsContext = () => {
  return useContext(StepsContext);
};

const StepsProvider = ({ children }) => {
  const stepsStack = useRef([]);
  const [state, dispatch] = useReducer(reducer, initialState);

  const isInStack = useCallback((stepIndex) => {
    return !!stepsStack.current.find((step) => step.index === stepIndex);
  }, []);

  const goToStep = useCallback(
    (step, stepState) => {
      stepsStack.current.push({ index: step, state: stepState });

      dispatch({
        type: actionTypes.SET_CURRENT_STEP,
        payload: { index: step, state: stepState },
      });
    },
    [dispatch]
  );

  const goBack = useCallback(() => {
    stepsStack.current.pop();

    dispatch({
      type: actionTypes.SET_CURRENT_STEP,
      payload: stepsStack.current[stepsStack.current.length - 1],
    });
  }, [dispatch]);

  const getStepState = useCallback((stepIndex) => {
    return stepsStack.current.find((step) => step.index === stepIndex)?.state;
  }, []);

  const values = useMemo(
    () => ({
      dispatch,
      goToStep,
      getStepState,
      isInStack,
      goBack,
      canGoBack: stepsStack.current.length > 1,
      ...state,
    }),
    [dispatch, goToStep, getStepState, isInStack, goBack, state]
  );

  return <StepsContext.Provider value={values}>{children}</StepsContext.Provider>;
};

export default StepsProvider;

StepsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
