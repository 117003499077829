import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import GenericTemplate from "../../../components/Template";
import PermitTypeTitle from "./PermitTypeTitle";

const Template = ({
  title,
  subTitle,
  children,
  isLoading,
  canGoBack,
  permitType,
  goBack,
}) => {
  return (
    <GenericTemplate
      onBack={canGoBack ? goBack : undefined}
      isLoading={isLoading}
      title={title}
      subTitle={subTitle}
    >
      <LeftContentContainer>
        {permitType && <PermitTypeTitle permitType={permitType} />}
        {children}
      </LeftContentContainer>
    </GenericTemplate>
  );
};

export default Template;

Template.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  canGoBack: PropTypes.bool,
  goBack: PropTypes.func,
  permitType: PropTypes.string,
};

Template.defaultProps = {
  isLoading: false,
  subTitle: undefined,
  canGoBack: false,
  goBack: () => {},
  permitType: "",
};

const LeftContentContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;
