import useCountries from "hooks/useCountries";
import useDistricts from "hooks/useDistricts";
import { useEffect, useState } from "react";
import { getPartyTypes } from "requests/clients";
import { getLocalities } from "requests/localities";
import { getAllStreets } from "requests/streets";
import { sortOrder } from "utils/server";

const useFormOptions = (entityId) => {
  const { countryOptions } = useCountries({
    entityId,
  });
  const { districtsOptions } = useDistricts(entityId);
  const [partyTypesOptions, setPartyTypesOptions] = useState([]);
  const [streetsOptions, setStreetsOptions] = useState([]);
  const [localitiesOptions, setLocalitiesOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const [partyTypesResponse, streetsResponse, localitiesResponse] = await Promise.all(
        [
          getPartyTypes(entityId, { noLimit: true }, true),
          getAllStreets(
            entityId,
            {
              noLimit: true,
              isAvailableForPermitHolders: 1,
              sortBy: "r.nome",
              sortOrder: sortOrder.asc,
            },
            true
          ),
          getLocalities(entityId, { noLimit: true }, true),
        ]
      );

      setPartyTypesOptions(
        partyTypesResponse.items.map((partyType) => ({
          label: partyType.designation,
          value: partyType.id,
        }))
      );

      setStreetsOptions(
        streetsResponse.items.map((street) => ({
          label: street.name,
          value: street.id,
        }))
      );

      setLocalitiesOptions(
        localitiesResponse.items.map((locality) => ({
          label: locality.name,
          value: locality.id,
          isDefault: locality.isDefault,
        }))
      );
    })();
  }, []);

  return {
    countries: countryOptions,
    partyTypes: partyTypesOptions,
    districts: districtsOptions,
    streets: streetsOptions,
    localities: localitiesOptions,
  };
};

export default useFormOptions;
