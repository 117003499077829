import { useAppContext } from "components/AppProvider";
import { useStepsContext } from "domain/public/components/StepsProvider";
import Step from "domain/public/components/StepsProvider/Step";
import Error400 from "domain/public/entities/components/errors/400";
import Error403 from "domain/public/entities/components/errors/403";
import { usePublicEntityContext } from "domain/public/entities/Provider";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getPermitTypeDocuments } from "requests/permitHolders";
import { entityConfigurationsMapping } from "utils/entities";
import Template from "../../../components/Template";
import { usePermitHolderRenewalContext } from "../Provider";
import ConfirmationStep from "./Confirmation";
import DocumentsStep from "./Documents";
import { steps } from "./helper";
import useSubtitle from "./hooks/useSubtitle";
import InfoStep from "./Info";

const PermitHolderRenewalSteps = () => {
  const { setIsLoading } = useAppContext();
  const { t } = useTranslation();
  const { goToStep, goBack, canGoBack } = useStepsContext();
  const { entityConfigs, getEntityConfig, entity } = usePublicEntityContext();
  const { hasError, isInitialDataLoading, renewalInfo } = usePermitHolderRenewalContext();
  const { currentStep } = useStepsContext();

  const subtitle = useSubtitle();

  const onConfirm = useCallback(() => {}, []);

  const onAdvanceDocumentsStep = useCallback(() => {
    goToStep(steps.confirmation, {
      onConfirm,
    });
  }, [goToStep, onConfirm]);

  const onAdvanceInfoStep = useCallback(async () => {
    setIsLoading(true);

    const response = await getPermitTypeDocuments(
      entity.id,
      renewalInfo.permitHolderType.id,
      { noLimit: true },
      { t }
    );

    setIsLoading(false);

    if (response.hasError) {
      return;
    }

    goToStep(steps.documents, {
      documents: response.items,
      onAdvance: onAdvanceDocumentsStep,
    });
  }, [entity, renewalInfo, onAdvanceDocumentsStep]);

  useEffect(() => {
    // Avoid call goToStep multiple times
    if (!renewalInfo.permitHolderType.id) {
      return;
    }

    goToStep(steps.info, { onAdvance: onAdvanceInfoStep });
  }, [renewalInfo]);

  if (
    entityConfigs &&
    !getEntityConfig(entityConfigurationsMapping.canCreatePublicPermits)
  ) {
    return <Error403 />;
  }

  if (hasError) {
    return <Error400 />;
  }

  return (
    <Template
      isLoading={isInitialDataLoading}
      subTitle={subtitle}
      canGoBack={canGoBack}
      goBack={goBack}
      permitType={
        currentStep.index !== steps.info
          ? renewalInfo.permitHolderType.designation
          : undefined
      }
      title={
        currentStep === steps.info
          ? t("12528") /* Está na hora de renovares o teu dístico! */
          : t("12540") /* Renovação de dístico */
      }
    >
      <Step step={steps.info}>
        <InfoStep />
      </Step>
      <Step step={steps.documents}>
        <DocumentsStep />
      </Step>
      <Step step={steps.confirmation}>
        <ConfirmationStep />
      </Step>
    </Template>
  );
};

export default PermitHolderRenewalSteps;
