import { STEPS_BEFORE_PERMIT_TYPE_CHOOSE } from "components/permitHolders/Creation/helper";
import useTitle from "components/permitHolders/Creation/hooks/useTitle";
import { usePermitHoldersContext } from "components/permitHolders/Creation/Provider";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Template from "../components/Template";

const PermitHolderCreationTemplate = (props) => {
  const { t } = useTranslation();
  const subTitle = useTitle();
  const {
    canGoBack,
    goBack,
    currentStep,
    selectedPermitType,
  } = usePermitHoldersContext();

  return (
    <Template
      subTitle={subTitle}
      canGoBack={canGoBack}
      goBack={goBack}
      title={t("10527") /* Criação de dístico */}
      permitType={
        !STEPS_BEFORE_PERMIT_TYPE_CHOOSE.includes(currentStep?.index)
          ? selectedPermitType?.permitType.designation
          : undefined
      }
      {...props}
    />
  );
};

export default PermitHolderCreationTemplate;

Template.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
};

Template.defaultProps = {
  isLoading: false,
};
