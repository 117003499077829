import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AdvanceButton from "../AdvanceButton";
import Card from "./Card";
import useDocumentsStep from "./useDocumentsStep";
import { submittedDocumentsProps } from "./propTypes";

const DocumentsStep = ({
  documents: documentsProp,
  submittedDocuments,
  onSubmitDocument,
  onRemoveDocument,
  onStepAdvance,
}) => {
  const { t } = useTranslation();
  const { documents, isStepTouched, onAdvance, isDocumentValid } = useDocumentsStep({
    documents: documentsProp,
    onStepAdvance,
    submittedDocuments,
  });

  return (
    <Container>
      <div>
        {documents.map((doc) => (
          <Card
            key={doc.id}
            onSubmitDocument={onSubmitDocument}
            onRemoveDocument={onRemoveDocument}
            isStepTouched={isStepTouched}
            isValid={isDocumentValid(doc.id)}
            submittedDocuments={submittedDocuments}
            {...doc}
          />
        ))}
        <Text className="mt-30" variant="body7" color="quintenary">
          * {t("10254") /* Documento(s) obrigatório(s) */}
        </Text>
      </div>
      <AdvanceButton type="button" onClick={onAdvance} />
    </Container>
  );
};

export default DocumentsStep;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

DocumentsStep.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      designation: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      isRequired: PropTypes.bool.isRequired,
    })
  ).isRequired,
  submittedDocuments: PropTypes.objectOf(PropTypes.shape(submittedDocumentsProps))
    .isRequired,
  onSubmitDocument: PropTypes.func.isRequired,
  onRemoveDocument: PropTypes.func.isRequired,
  onStepAdvance: PropTypes.func.isRequired,
};
