import React from "react";
import { useLocation } from "react-router-dom";
import { statusStates } from "./helper";
import NotRegisteredStatus from "./NotRegistered";
import RegisteredWithAssociatedAccountStatus from "./RegisteredWithAssociatedAccount";
import RegisteredWithoutAssociatedAccountStatus from "./RegisteredWithoutAssociatedAccount";

const ConfirmationStatus = () => {
  const { state = {} } = useLocation();
  const { allowRegistry, associated } = state;

  const statusState = (() => {
    if (associated) {
      return statusStates.registeredWithAssociatedAccount;
    }

    return allowRegistry
      ? statusStates.notRegistered
      : statusStates.registeredWithoutAssociatedAccount;
  })();

  const stateStatusComponent = {
    [statusStates.notRegistered]: NotRegisteredStatus,
    [statusStates.registeredWithoutAssociatedAccount]: RegisteredWithoutAssociatedAccountStatus,
    [statusStates.registeredWithAssociatedAccount]: RegisteredWithAssociatedAccountStatus,
  };

  const StatusComponent = stateStatusComponent[statusState];

  return <StatusComponent />;
};

export default ConfirmationStatus;
