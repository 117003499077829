import Text from "components/Text";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const LocationCard = ({ name, locations }) => {
  return (
    <Container>
      <Text variant="body2" color="quintenary">
        {name}
      </Text>
      {locations.map((location) => (
        <Text className="mt-5" variant="body6" color="quintenary">
          {location.name}
        </Text>
      ))}
    </Container>
  );
};

export default LocationCard;

LocationCard.propTypes = {
  name: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.card.dense.primary.backgroundColor};
  padding: 20px 15px;

  &:not(:first-of-type) {
    margin-top: 10px;
  }
`;
