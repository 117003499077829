import Link from "components/Link";
import React from "react";
import { Trans } from "react-i18next";
import { routesMapping } from "utils/routes";
import Body from "./components/Body";

const RegisteredWithAssociatedAccountStatus = () => {
  return (
    <Body color="quintenary" className="inline mt-10" variant="body5">
      <Trans
        i18nKey="12566"
        components={{
          a: <Link target="_blank" underline="always" href={routesMapping.login} />,
        }}
      />
      {/* Acede à tua conta iParque Driver e verifica as alterações do dístico. */}
    </Body>
  );
};

export default RegisteredWithAssociatedAccountStatus;
