import ConditionalWrapper from "components/ConditionalWrapper";
import CheckboxField from "components/formFields/Checkbox";
import InlineDetails from "components/InlineDetails";
import { Formik } from "formik";
import { Link, Text } from "iparque-components";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getMonthLabel } from "utils/dateTime";
import { getReadablePaymentPeriodicity, paymentPeriodicityTypes } from "utils/payment";
import Yup from "yup.js";
import AdvanceButton from "../../../components/AdvanceButton";
import Form from "../../components/Form";
import { useStepContext } from "../../components/Step";
import { CREATION_CONTEXT } from "../../helper";
import { usePermitHoldersContext } from "../../Provider";
import { actionTypes } from "../../store/actions";
import PriceCard from "./PriceCard";

const typographyMap = {
  body6: {
    [CREATION_CONTEXT.public]: "body6",
    [CREATION_CONTEXT.private]: "body4",
  },
};

const PermitDetailsStep = ({ onBeforeAdvance, WrapperComponent }) => {
  const { t } = useTranslation();
  const {
    termsAndConditionAcceptance,
    dispatch,
    creationContext,
    isPrivateContext,
  } = usePermitHoldersContext();
  const formRef = useRef(null);
  const {
    onAdvance,
    prices,
    paymentPeriodicity,
    regulationAttachment,
    renewalDeadlineDay,
    beginningDay,
    beginningMonth,
    renewalQuantity,
  } = useStepContext();

  const localOnAdvance = async () => {
    dispatch({ type: actionTypes.SET_TERMS_AND_CONDITIONS_ACCEPTED, payload: true });

    await onBeforeAdvance();

    onAdvance();
  };

  const onClickAdvance = () => {
    formRef.current?.submitForm();
  };

  const details = (() => {
    if (!paymentPeriodicity?.id) {
      return []; // Permit has no payment
    }

    const localDetails = [
      {
        label: t("10211") /* Periodicidade do pagamento */,
        value:
          renewalQuantity > 1
            ? getReadablePaymentPeriodicity(paymentPeriodicity.id, renewalQuantity, {
                t,
              })
            : paymentPeriodicity.designation,
      },
    ];

    if (paymentPeriodicityTypes.monthly === paymentPeriodicity?.id && beginningDay > 0) {
      localDetails.push({
        label: t("10212") /* Data de início  */,
        value: t("10213", {
          day: beginningDay,
        }) /* {{day}}º dia do mês */,
      });
    }

    if (
      paymentPeriodicityTypes.yearly === paymentPeriodicity?.id &&
      beginningMonth > 0 &&
      beginningDay > 0
    ) {
      localDetails.push({
        label: t("10212") /* Data de início  */,
        value: t("10215", {
          day: beginningDay,
          month: getMonthLabel(beginningMonth),
        }) /* {{day}}º dia de {{month}} */,
      });
    }

    if (renewalDeadlineDay > 0) {
      localDetails.push({
        label: t("10214") /* Prazo para renovação */,
        value: `${renewalDeadlineDay} ${
          renewalDeadlineDay === 1 ? t("8012") /* dia */ : t("1297") /* dias */
        }`,
      });
    }

    return localDetails;
  })();

  return (
    <>
      <ConditionalWrapper wrapper={WrapperComponent} condition={!!WrapperComponent}>
        {details.length > 0 && <InlineDetails color="quintenary" details={details} />}
        <PriceLabel className="mt-30" color="quintenary" variant="body1">
          {t("10226") /* Preçário */}:
        </PriceLabel>
        {prices?.map((price) => (
          <PriceCard
            className="mt-10"
            key={price.designation}
            {...price}
            price={price.price * renewalQuantity}
          />
        ))}
        <Formik
          innerRef={formRef}
          initialValues={{
            termsConditions: termsAndConditionAcceptance,
          }}
          validationSchema={Yup.object({
            termsConditions: Yup.bool().oneOf(
              [true],
              t("10224") /* Deves aceitar as condições apresentadas */
            ),
          })}
          onSubmit={localOnAdvance}
        >
          <Form className="mt-20">
            <CheckboxField
              name="termsConditions"
              isPrivateContext={isPrivateContext}
              label={
                <ConditionsText
                  color="quintenary"
                  variant={typographyMap.body6[creationContext]}
                >
                  {
                    regulationAttachment ? (
                      <>
                        {t("10221") /* Confirmo o preçário bem como as */}{" "}
                        <Link
                          underline="always"
                          target="_blank"
                          href={regulationAttachment.url}
                        >
                          {t("10222") /* Condições */}
                        </Link>
                      </>
                    ) : (
                      t("10220")
                    ) /* Confirmo e aceito as condições apresentadas */
                  }
                </ConditionsText>
              }
            />
          </Form>
        </Formik>
      </ConditionalWrapper>
      <AdvanceButton type="button" onClick={onClickAdvance} />
    </>
  );
};

export default PermitDetailsStep;

PermitDetailsStep.propTypes = {
  WrapperComponent: PropTypes.node,
  onBeforeAdvance: PropTypes.func,
};

PermitDetailsStep.defaultProps = { WrapperComponent: <></>, onBeforeAdvance: () => {} };

const PriceLabel = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.weight.bold};
`;

const ConditionsText = styled(Text)`
  line-height: 20px;
`;
