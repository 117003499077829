import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { infoMessage } from "utils/userMessages";
import { useStepContext } from "../../components/Step";
import { usePermitHoldersContext } from "../../Provider";
import { actionTypes } from "../../store/actions";
import {
  canAddVehicles,
  emptyVehicle,
  getNextRegime,
  getPositionOfEditedVehicle,
  isEditing as checkIfIsEditing,
} from "../../../helpers/vehicles";

const useVehiclesStep = () => {
  const { t } = useTranslation();
  const { dispatch, entity, vehicles } = usePermitHoldersContext();
  const { vehicleRegimes, onAdvance } = useStepContext();

  const isEditing = useMemo(() => checkIfIsEditing(vehicles), [vehicles]);

  const localOnAdvance = useCallback(() => {
    if (isEditing) {
      infoMessage(
        t("12812") /* Para poder avançar deve finalizar o veículo pendente */
      );

      dispatch({
        type: actionTypes.FLAG_VEHICLE,
        payload: { position: getPositionOfEditedVehicle(vehicles) },
      });

      return;
    }

    onAdvance();
  }, [vehicles, isEditing]);

  const addVehicle = useCallback(() => {
    if (!canAddVehicles(vehicles, vehicleRegimes)) {
      infoMessage(t("10264") /* Não podem ser adicionados mais veículos */);
      return;
    }

    dispatch({
      type: actionTypes.ADD_VEHICLE,
      payload: {
        ...emptyVehicle,
        countryId: entity.countryId,
        regime: getNextRegime(vehicles, vehicleRegimes),
      },
    });
  }, [vehicles, vehicleRegimes, entity?.countryId]);

  useEffect(() => {
    if (vehicles.length === 0) {
      addVehicle(); // Add form entry on start
    }
  }, []);

  const editVehicle = useCallback(
    (position, vehicle) =>
      dispatch({ type: actionTypes.EDIT_VEHICLE, payload: { position, vehicle } }),
    []
  );

  const makeEditable = useCallback(
    (position) => {
      if (isEditing) {
        infoMessage(
          t(
            "10262"
          ) /* Para poderes editar o veículo deves finalizar o veículo pendente */
        );

        dispatch({
          type: actionTypes.FLAG_VEHICLE,
          payload: { position: getPositionOfEditedVehicle(vehicles) },
        });

        return;
      }

      dispatch({ type: actionTypes.MAKE_VEHICLE_EDITABLE, payload: { position } });
    },
    [vehicles, isEditing]
  );

  const removeVehicle = useCallback(
    (position) => dispatch({ type: actionTypes.REMOVE_VEHICLE, payload: { position } }),
    []
  );

  return {
    vehicles,
    onAdvance: localOnAdvance,
    addVehicle,
    editVehicle,
    makeEditable,
    removeVehicle,
    isEditing,
  };
};

export default useVehiclesStep;
