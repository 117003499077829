import i18n from "i18n";
import PermitHoldersDataSource from "lib/clients/iParque/dataSources/permitHolders";
import { attachmentTypes } from "utils/attachments";
import { formatDateTime } from "utils/dateTime";
import {
  hasErrorCode,
  MAXIMUM_NUMBER_PERMIT_HOLDER_PROCESSES_ALLOWED_SAME_ADDRESS_HAS_BEEN_REACHED,
  MAXIMUM_NUMBER_PERMIT_HOLDER_PROCESSES_ALLOWED_SAME_TAX_IDENTIFICATION_NUMBER_HAS_BEEN_REACHED,
  PERMIT_HOLDER_ALREADY_ASSOCIATED,
  REQUIRE_PERMITS,
  REQUIRE_PERMIT_TYPE_DOCUMENTS,
  REQUIRE_PERMIT_TYPE_VEHICLE_REGIMES,
  REQUIRE_THE_ORDER_OF_VEHICLE_REGIMES,
} from "utils/error";
import { permitHoldersOccupationType, permitHolderStates } from "utils/permitHolders";
import {
  customerSupportMessage,
  errorMessage,
  infoMessage,
  warningMessage,
} from "utils/userMessages";
import { userTypes } from "utils/users";
import { createAttachment } from "./attachments";

const permitHoldersDataSource = new PermitHoldersDataSource();

const DEFAULT_RENEWAL_QUANTITY = 1;

const processPermitType = (permitType) => {
  const permitProcessed = {
    id: permitType.id,
    designation: permitType.designation,
    beginningDay: permitType.beginingDay,
    beginningMonth: permitType.beginingMonth,
    renewalQuantity: permitType.renewalQuantity || DEFAULT_RENEWAL_QUANTITY,
    renewalDeadlineDay: permitType.renewalTerm,
    occupationTypeId: permitType.occupationTypeId,
    regulationAttachment: permitType.attachment
      ? {
          name: permitType.attachment.name,
          url: permitType.attachment.path,
        }
      : undefined,
    paymentPeriodicity: permitType.paymentPeriodicity,
    shouldRegisterAddressStreet: permitType.shouldRegisterAddressStreet,
  };

  if (permitType.occupationTypeId === permitHoldersOccupationType.vehicle) {
    permitProcessed.prices = permitType.vehicleRegimeTypes.map((vehicleRegime) => ({
      designation: vehicleRegime.vehicleRegimeType.designation,
      price: vehicleRegime.value,
    }));
  } else {
    permitProcessed.prices = [
      {
        designation: i18n.t("10219") /* Lugar de estacionamento */,
        price: permitType.monthlyPayment,
      },
    ];
  }

  return permitProcessed;
};

const processPermitTypes = (permitTypes) => {
  const newPermits = [];

  permitTypes.forEach((permitType) => {
    if (
      permitType.occupationTypeId === permitHoldersOccupationType.vehicle &&
      !permitType.vehicleRegimeTypes
    ) {
      return;
    }

    newPermits.push(processPermitType(permitType));
  });

  return newPermits;
};

const processVehiclesRegimes = (vehicleRegimes) =>
  vehicleRegimes.sort(
    (vehicleRegime, nextVehicleRegime) => vehicleRegime.order - nextVehicleRegime.order
  );

const getWarning = (details) => {
  const warnings = { ...details.warnings } || {};
  const stateId = details?.stateId || details.state.id;

  delete warnings.noStreetsAssociated; // Not used in the driver backoffice

  if (stateId === permitHolderStates.rejected && details.status.motive) {
    warnings.rejection = {
      motive: details.status.motive.description,
      observations: details.status.observations,
    };
  }

  if (warnings.requiredDocuments?.length && warnings.expiredDocuments?.length) {
    const expiredDocumentIds = warnings.expiredDocuments.map((doc) => doc.id);

    warnings.requiredDocuments = warnings.requiredDocuments.filter(
      (doc) => !expiredDocumentIds.includes(doc.id)
    );
  }

  return warnings;
};

export const getPermitTypes = async (entityId, cityId, params = {}) => {
  try {
    const response = await permitHoldersDataSource.getTypes(
      entityId,
      cityId,
      params,
      true
    );

    const permitTypes = processPermitTypes(response.items);

    if (!permitTypes.length) {
      customerSupportMessage(REQUIRE_PERMITS);

      return { hasError: true };
    }

    return {
      items: permitTypes,
      totalItems: response.totalItems,
    };
  } catch (error) {
    errorMessage(
      error,
      i18n.t("10533") /* Ocorreu um erro ao obter os tipos de dísticos */
    );

    return { hasError: true };
  }
};

export const getPermitTypeDocuments = async (
  entityId,
  permitTypeId,
  params = {},
  { t = i18n.t } = {}
) => {
  try {
    const response = await permitHoldersDataSource.getPermitTypeDocuments(
      entityId,
      permitTypeId,
      params,
      true
    );

    if (!response.items.length) {
      customerSupportMessage(REQUIRE_PERMIT_TYPE_DOCUMENTS);

      return { hasError: true };
    }

    return {
      items: response.items,
      totalItems: response.totalItems,
    };
  } catch (error) {
    errorMessage(
      error,
      t(
        "10590"
      ) /* Ocorreu um erro ao obter os tipos de documentos necessários ao dístico */
    );

    return { hasError: true };
  }
};

export const getPermitTypeVehicleRegimes = async (
  entityId,
  permitTypeId,
  params = {}
) => {
  try {
    const response = await permitHoldersDataSource.getPermitTypeVehicles(
      entityId,
      permitTypeId,
      params,
      true
    );

    if (!response.items.length) {
      customerSupportMessage(REQUIRE_PERMIT_TYPE_VEHICLE_REGIMES);

      return { hasError: true };
    }

    if (response.items.find((vehicleRegime) => !vehicleRegime.order)) {
      customerSupportMessage(REQUIRE_THE_ORDER_OF_VEHICLE_REGIMES);

      return { hasError: true };
    }

    return {
      items: processVehiclesRegimes(response.items),
      totalItems: response.totalItems,
    };
  } catch (error) {
    errorMessage(
      error,
      i18n.t(
        "10534"
      ) /* Ocorreu um erro ao obter os regimes do veículos disponíveis para o dístico */
    );

    return { hasError: true };
  }
};

export const createPermitHolder = async (
  entityId,
  data,
  { autoCloseError = true } = {}
) => {
  try {
    const response = await permitHoldersDataSource.create(entityId, data);

    return {
      permitHolderId: response.id,
      permitHolderToken: response.token,
      allowRegistry: response.allowRegistry,
      hasSubmissionRate: response.hasSubmissionRate,
    };
  } catch (error) {
    if (
      hasErrorCode(
        error,
        MAXIMUM_NUMBER_PERMIT_HOLDER_PROCESSES_ALLOWED_SAME_TAX_IDENTIFICATION_NUMBER_HAS_BEEN_REACHED
      ) ||
      hasErrorCode(
        error,
        MAXIMUM_NUMBER_PERMIT_HOLDER_PROCESSES_ALLOWED_SAME_ADDRESS_HAS_BEEN_REACHED
      )
    ) {
      warningMessage(
        i18n.t("10535") /* O número máximo de dísticos criados foi atingido */
      );

      return null;
    }

    errorMessage(
      error,
      i18n.t("10536") /* Ocorreu um erro ao guardar o dístico */,
      null,
      {
        autoClose: autoCloseError,
      }
    );

    return null;
  }
};

export const updatePermitHolder = async (
  entityId,
  driverHash,
  permitHolderId,
  data,
  { t } = {}
) => {
  try {
    const response = await permitHoldersDataSource.update(
      entityId,
      driverHash,
      permitHolderId,
      data
    );

    return { permitHolderId: response.id, permitHolderToken: response.token };
  } catch (error) {
    if (
      hasErrorCode(
        error,
        MAXIMUM_NUMBER_PERMIT_HOLDER_PROCESSES_ALLOWED_SAME_TAX_IDENTIFICATION_NUMBER_HAS_BEEN_REACHED
      )
    ) {
      warningMessage(
        i18n.t("10535") /* O número máximo de dísticos criados foi atingido */
      );

      return null;
    }

    errorMessage(error, t("10646") /* Ocorreu um erro ao editar o dístico */);

    return null;
  }
};

export const deletePermitHolder = async (entityId, permitHolderToken, params) => {
  try {
    await permitHoldersDataSource.delete(entityId, permitHolderToken, params);

    return true;
  } catch {
    return false;
  }
};

export const addPermitHolderVehicle = async (
  entityId,
  { permitHolderId, driverHash, data },
  { autoCloseError = true } = {}
) => {
  try {
    await permitHoldersDataSource.addVehicle(entityId, {
      driverHash,
      permitHolderId,
      data,
    });

    return true;
  } catch (error) {
    errorMessage(
      error,
      i18n.t("10290") /* Ocorreu um erro ao guardar o veículo */,
      null,
      { autoClose: autoCloseError }
    );

    return false;
  }
};

export const updatePermitHolderVehicle = async (
  entityId,
  driverId,
  permitHolderId,
  vehicleId,
  driverHash,
  data,
  { t }
) => {
  try {
    await permitHoldersDataSource.updateVehicle(
      entityId,
      driverHash,
      permitHolderId,
      vehicleId,
      { ...data, userTypeId: userTypes.client, userId: driverId }
    );

    return true;
  } catch (error) {
    errorMessage(error, t("7974") /* Ocorreu um erro ao editar o veículo */);

    return false;
  }
};

export const removePermitHolderVehicle = async (
  entityId,
  driverHash,
  permitHolderId,
  vehicleId,
  data,
  { t }
) => {
  try {
    await permitHoldersDataSource.removeVehicle(
      entityId,
      driverHash,
      permitHolderId,
      vehicleId,
      data
    );

    return true;
  } catch (error) {
    errorMessage(error, t("7976") /* Ocorreu um erro ao eliminar o veículo */);

    return false;
  }
};

const addPermitHolderDocument = async (
  entityId,
  permitHolderId,
  data,
  { autoCloseError = true } = {}
) => {
  try {
    await permitHoldersDataSource.addDocument(entityId, permitHolderId, data);

    return true;
  } catch (error) {
    errorMessage(
      error,
      i18n.t("10291") /* Ocorreu um erro ao guardar o documento */,
      null,
      { autoClose: autoCloseError }
    );

    return false;
  }
};

export const deletePermitHolderDocument = async (
  entityId,
  driverHash,
  permitHolderId,
  documentId,
  params,
  { t }
) => {
  try {
    await permitHoldersDataSource.deleteDocument(
      entityId,
      driverHash,
      permitHolderId,
      documentId,
      params
    );

    return true;
  } catch (error) {
    errorMessage(error, t("9052") /* Ocorreu um erro ao eliminar o documento */);

    return false;
  }
};

export const changePermitHolderState = async (
  entityId,
  permitHolderId,
  data,
  { autoCloseError = true } = {}
) => {
  try {
    await permitHoldersDataSource.changeState(entityId, permitHolderId, data);

    return true;
  } catch (error) {
    errorMessage(
      error,
      i18n.t("10538") /* Ocorreu um erro ao alterar o estado do dístico */,
      null,
      { autoClose: autoCloseError }
    );

    return false;
  }
};

export const changeAndReturnPermitHolderState = async (
  entityId,
  driverHash,
  permitHolderId,
  permitHolderToken,
  data,
  { autoCloseError = true } = {}
) => {
  try {
    await permitHoldersDataSource.changeState(entityId, permitHolderToken, data);
    const details = await permitHoldersDataSource.getById(
      entityId,
      driverHash,
      permitHolderId,
      {
        fillCollections: "state",
      }
    );

    return { id: details.state.id, designation: details.state.name };
  } catch (error) {
    errorMessage(
      error,
      i18n.t("10538") /* Ocorreu um erro ao alterar o estado do dístico */,
      null,
      { autoClose: autoCloseError }
    );

    return null;
  }
};

export const getPermitHolderEvidence = async (entityId, permitHolderId) => {
  try {
    const response = await permitHoldersDataSource.getPermitHolderEvidence(
      entityId,
      permitHolderId
    );

    return response;
  } catch (error) {
    errorMessage(
      error,
      i18n.t("10539") /* Ocorreu um erro ao obter o comprovativo de criação do dístico */
    );

    return null;
  }
};

export const getPermitHolderDetails = async (
  entityId,
  driverHash,
  permitHolderId,
  params = {},
  { language, t }
) => {
  try {
    const details = await permitHoldersDataSource.getById(
      entityId,
      driverHash,
      permitHolderId,
      {
        fillCollections: "state,client,permit,status",
        ...params,
      }
    );

    return {
      reference: details.reference,
      token: details.token,
      city: details.city,
      createdDateTime: formatDateTime(details.createdDate, language),
      state: { id: details.state.id, designation: details.state.name },
      permit: {
        id: details.permit.id,
        occupationTypeId: details.permit.occupationTypeId,
        type: details.permit.permitType,
      },
      clientId: details.client.id,
      personalData: details.client
        ? {
            partyTypeId: details.client.partyType.id,
            name: details.client.name,
            countryId: details.client.country?.id,
            taxpayerIdentificationNumber: details.client.taxpayerIdentificationNumber,
            commercialName: details.client.commercialName || "",
            economicActivityCode: details.client.economicActivityCode || undefined,
            address: details.client.address,
            block: details.client.block || "",
            floor: details.client.floor || "",
            doorNumber: details.client.doorNumber || "",
            zipCode: details.client.zipCode,
            locality: details.client.locality,
            districtId: details.client.district.id,
            email: details.client.email || undefined,
            phone: details.client.phone || undefined,
            mobilePhone: details.client.mobilePhone || undefined,
          }
        : {},
      warnings: getWarning(details),
    };
  } catch (error) {
    errorMessage(error, t("10584") /* Ocorreu um erro ao obter os detalhes do dístico */);

    return null;
  }
};

export const getPermitHolderWarnings = async (
  entityId,
  driverId,
  permitHolderId,
  params = {},
  { t }
) => {
  try {
    const details = await permitHoldersDataSource.getById(
      entityId,
      driverId,
      permitHolderId,
      {
        fillCollections: "status",
        ...params,
      }
    );

    return getWarning(details);
  } catch (error) {
    errorMessage(error, t("10640") /* Ocorreu um erro ao atualizar a lista warnings */);

    return null;
  }
};

export const associatePermitHolderDriver = async (
  entityId,
  driverHash,
  permitHolderId,
  {
    errorMsg = i18n.t("10508") /* Ocorreu um erro ao associar o dístico a tua conta */,
    autoCloseError = true,
    t,
  } = {}
) => {
  try {
    await permitHoldersDataSource.associateDriver(entityId, driverHash, permitHolderId);

    return true;
  } catch (error) {
    if (hasErrorCode(error, PERMIT_HOLDER_ALREADY_ASSOCIATED)) {
      infoMessage(t("10587") /* O dístico já se encontra associado à tua conta */, null, {
        autoClose: autoCloseError,
      });

      return false;
    }

    errorMessage(error, errorMsg, null, { autoClose: autoCloseError });

    return false;
  }
};

export const saveDocument = async (
  entityId,
  permitHolderId,
  { file, typeId, userId },
  { autoCloseError = true } = {}
) => {
  const attachmentResponse = await createAttachment(
    entityId,
    file,
    {
      attachmentTypeId: attachmentTypes.permitHolders,
      fileName: file.name,
    },
    { autoCloseError }
  );

  if (!attachmentResponse) {
    return false;
  }

  const { attachmentId } = attachmentResponse;

  const documentCreationResult = await addPermitHolderDocument(
    entityId,
    permitHolderId,
    {
      attachmentId,
      typeId,
      userTypeId: userTypes.client,
      userId,
    },
    { autoCloseError }
  );

  return documentCreationResult;
};

export const getPermitHolderRenewal = async (
  entityToken,
  renewalToken,
  params = {},
  { t }
) => {
  try {
    const details = await permitHoldersDataSource.getRenewal(
      entityToken,
      renewalToken,
      params
    );

    return details;
  } catch (error) {
    errorMessage(
      error,
      t("12529") /* Ocorreu um erro ao obter os dados da renovação pendente */
    );

    return null;
  }
};

export const submitPermitHolderRenewal = async (
  entityId,
  renewalToken,
  params,
  { t }
) => {
  try {
    const details = await permitHoldersDataSource.submitRenewal(
      entityId,
      renewalToken,
      params
    );

    return details;
  } catch (error) {
    errorMessage(error, t("12530") /* Ocorreu um erro ao submeter a renovação */);

    return null;
  }
};

export const getPermitHolderRenewalEvidence = async (
  entityId,
  renewalToken,
  params = {},
  { t }
) => {
  try {
    const response = await permitHoldersDataSource.getRenewalEvidence(
      entityId,
      renewalToken,
      params
    );

    return response;
  } catch (error) {
    errorMessage(
      error,
      t("12567") /* Ocorreu um erro ao obter o comprovativo de renovação do dístico */
    );

    return null;
  }
};

const processPermitParkingRulesAuthorizations = (parkingRulesAuthorizations) => {
  const newParkingRuleAuthorizations = {};

  parkingRulesAuthorizations.forEach((parkingRulesAuthorization) => {
    const locationTypeId = parkingRulesAuthorization.authorizedLocationType.id;

    if (!newParkingRuleAuthorizations[locationTypeId]) {
      newParkingRuleAuthorizations[locationTypeId] = {
        name: parkingRulesAuthorization.authorizedLocationType.name,
        data: [],
      };
    }

    newParkingRuleAuthorizations[locationTypeId].data.push(
      parkingRulesAuthorization.authorizedLocation
    );
  });

  return Object.values(newParkingRuleAuthorizations);
};

export const getPermitParkingRulesAuthorizations = async (entityId, params = {}) => {
  try {
    const response = await permitHoldersDataSource.getPermitParkingRulesAuthorizations(
      entityId,
      { ...params, fillCollections: "authorizedLocationType" }
    );

    const parkingRulesAuthorizations = processPermitParkingRulesAuthorizations(
      response.items
    );

    return parkingRulesAuthorizations;
  } catch (error) {
    errorMessage(
      error,
      i18n.t("12656") /* Ocorreu um erro ao obter locais autorizados */
    );

    return { hasError: true };
  }
};
