import i18n from "i18n";
import StreetsDataSource from "lib/clients/iParque/dataSources/streetsDataSource";
import { errorMessage } from "utils/userMessages";

const streetsDataSource = new StreetsDataSource();

export const getAllStreets = async (entityId, params = {}, useCache = false) => {
  try {
    const response = await streetsDataSource.getAllStreets(entityId, params, useCache);

    return {
      items: response.items,
      totalItems: response.totalItems,
    };
  } catch (error) {
    errorMessage(error, i18n.t("7534") /* Ocorreu um erro ao obter as ruas */);

    return { hasError: true, items: [] };
  }
};
