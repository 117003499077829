import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Text } from "iparque-components";
import AdvanceButton from "components/permitHolders/components/AdvanceButton";
import useDocumentsStep from "components/permitHolders/Creation/defaultSteps/Documents/useDocumentsStep";
import FlexBox from "components/FlexBox";
import { breakpoints } from "utils/breakpoints";
import Card from "./Card";
import SmallDevicesFillSpace from "../../components/SmallDevicesFillSpace";

const DocumentsStep = () => {
  const { t } = useTranslation();
  const {
    documents,
    isStepTouched,
    onAdvance,
    onSubmitDocument,
    onRemoveDocument,
    isDocumentValid,
  } = useDocumentsStep();

  return (
    <FlexBox flexDirection="column" className="fill">
      <InputsContainer>
        {documents.map((doc) => (
          <Card
            key={doc.id}
            onSubmitDocument={onSubmitDocument}
            onRemoveDocument={onRemoveDocument}
            isStepTouched={isStepTouched}
            isValid={isDocumentValid(doc.id)}
            {...doc}
          />
        ))}
      </InputsContainer>
      <Text className="mt-30" variant="body4" color="quintenary">
        * {t("10254") /* Documento(s) obrigatório(s) */}
      </Text>
      <SmallDevicesFillSpace />
      <AdvanceButton type="button" onClick={onAdvance} />
    </FlexBox>
  );
};

export default DocumentsStep;

const InputsContainer = styled(FlexBox)`
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`;
