import TextField from "components/formFields/Text";
import FormGridContainer, { TemplateFieldContainer } from "components/FormGrid";
import PasswordRequirements from "components/PasswordRequirements";
import AdvanceButton from "components/permitHolders/components/AdvanceButton";
import Form from "components/permitHolders/Creation/components/Form";
import RegistryConditions from "components/RegistryConditions";
import { ValidationSchemaProvider } from "components/ValidationSchemaProvider";
import RecaptchaInfo from "domain/public/components/RecaptchaInfo";
import { Formik } from "formik";
import useRegistry from "hooks/useRegistry";
import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Yup from "yup.js";

const RegistryForm = () => {
  const { t } = useTranslation();
  const passwordRef = useRef();
  const { state = {} } = useLocation();
  const { name, taxpayerIdentificationNumber, email, countryId } = state;
  const { isGoogleRecaptchaLoaded, onSubmit } = useRegistry();

  const initialValues = useMemo(
    () => ({
      email,
      password: "",
      passwordConfirmation: "",
      termsConditions: false,
      privacyPolicy: false,
      newslettersNewFeatures: false,
      newslettersMarketCampaigns: false,
    }),
    [email]
  );

  const localOnSubmit = useCallback(
    (values) =>
      onSubmit({ ...values, name, vat: taxpayerIdentificationNumber, countryId }),
    [taxpayerIdentificationNumber, countryId, name, onSubmit]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object({
        email: Yup.string()
          .required(t("10271") /* O campo é obrigatório */)
          .email(t("7894") /* O campo deverá ser válido */),
        password: Yup.string()
          .required(t("10271") /* O campo é obrigatório */)
          .password(
            t("8154") /* O campo deverá preencher os requisitos apresentados abaixo */,
            passwordRef
          ),
        passwordConfirmation: Yup.string()
          .required(t("10271") /* O campo é obrigatório */)
          .oneOf(
            [Yup.ref("password"), null],
            t("7883") /* A confirmação da senha não corresponde com a senha introduzida */
          ),
        termsConditions: Yup.bool().oneOf(
          [true],
          t("11868") /* Terás de aceitar os Termos de Utilização */
        ),
        privacyPolicy: Yup.bool().oneOf(
          [true],
          t("11869") /* Terás de aceitar a Política de Privacidade */
        ),
      }),
    [t]
  );

  return (
    <ValidationSchemaProvider schema={validationSchema}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={localOnSubmit}
      >
        {({ values }) => (
          <Form>
            <FormGridContainer>
              <TemplateFieldContainer>
                <TextField
                  type="email"
                  id="email-field"
                  name="email"
                  label={t("178") /* Email */}
                  disabled
                />
              </TemplateFieldContainer>
              <TemplateFieldContainer />
              <TemplateFieldContainer>
                <TextField
                  type="password"
                  id="password-field"
                  name="password"
                  label={t("3550") /* Senha */}
                />
              </TemplateFieldContainer>
              <TemplateFieldContainer>
                <TextField
                  type="password"
                  id="password-confirmation-field"
                  name="passwordConfirmation"
                  label={t("7884") /* Confirmar Senha */}
                />
              </TemplateFieldContainer>
              <TemplateFieldContainer fullWidth>
                <PasswordRequirements ref={passwordRef} password={values.password} />
              </TemplateFieldContainer>
              <div className="mt-20" />
              <RegistryConditions textColor="quintenary" />
            </FormGridContainer>
            <AdvanceButton
              type="submit"
              className="mt-40"
              disabled={!isGoogleRecaptchaLoaded}
            >
              {t("3949") /* Registar */}
            </AdvanceButton>
            <RecaptchaInfo className="mt-30" />
          </Form>
        )}
      </Formik>
    </ValidationSchemaProvider>
  );
};

export default RegistryForm;
