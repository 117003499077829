import { useCallback } from "react";
import { partyTypes } from "utils/clients";
import { usePermitHoldersContext } from "../../Provider";

const useForm = () => {
  const { entity, personalData, selectedCity } = usePermitHoldersContext();

  const getInitialData = useCallback(
    () =>
      personalData || {
        partyTypeId: partyTypes.person,
        name: "",
        countryId: entity?.countryId,
        taxpayerIdentificationNumber: "",
        commercialName: "",
        economicActivityCode: "",
        address: "",
        block: "",
        floor: "",
        doorNumber: "",
        zipCode: "",
        locality: "",
        districtId: selectedCity.districtId,
        email: "",
        phone: "",
        mobilePhone: "",
        localityId: "",
        addressStreetId: "",
      },
    [personalData, entity]
  );

  return {
    getInitialData,
  };
};

export default useForm;
