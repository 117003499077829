import { getTokens } from "utils/auth";
import AbstractDataSource from "./abstractDataSource";

export default class PermitHoldersDataSource extends AbstractDataSource {
  async getTypes(entityId, cityId, params = {}, useCache = false) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/explorations/${cityId}/permits/types`,
      params,
      {},
      useCache
    );
  }

  async getPermitTypeDocuments(entityId, permitTypeId, params = {}, useCache = false) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/permits/types/${permitTypeId}/documents`,
      params,
      {},
      useCache
    );
  }

  async getPermitTypeVehicles(entityId, permitTypeId, params = {}, useCache = false) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/permits/types/${permitTypeId}/vehicles`,
      params,
      {},
      useCache
    );
  }

  async create(entityId, data) {
    return this.iParqueClient.POST(`v2/entities/${entityId}/permits/holders`, data);
  }

  async update(entityId, driverHash, permitHolderId, data) {
    return this.iParqueClient.PUT(
      `v2/entities/${entityId}/drivers/${driverHash}/permits/holders/${permitHolderId}`,
      data,
      { Authorization: getTokens().token }
    );
  }

  async delete(entityId, permitHolderToken, data) {
    return this.iParqueClient.DELETE(
      `v1/entities/${entityId}/permits/holders/${permitHolderToken}`,
      data
    );
  }

  async addVehicle(entityId, { driverHash, permitHolderId, data }) {
    return driverHash
      ? this.iParqueClient.POST(
          `v1/entities/${entityId}/drivers/${driverHash}/permits/holders/${permitHolderId}/vehicles`,
          data,
          { Authorization: getTokens().token }
        )
      : this.iParqueClient.POST(
          `v1/entities/${entityId}/permits/holders/${permitHolderId}/vehicles`,
          data
        );
  }

  async updateVehicle(entityId, driverHash, permitHolderId, vehicleId, data) {
    return this.iParqueClient.PUT(
      `v1/entities/${entityId}/drivers/${driverHash}/permits/holders/${permitHolderId}/vehicles/${vehicleId}`,
      data,
      { Authorization: getTokens().token }
    );
  }

  async removeVehicle(entityId, driverHash, permitHolderId, vehicleId, params) {
    return this.iParqueClient.DELETE(
      `v1/entities/${entityId}/drivers/${driverHash}/permits/holders/${permitHolderId}/vehicles/${vehicleId}`,
      params,
      { Authorization: getTokens().token }
    );
  }

  async changeState(entityId, permitHolderId, data) {
    return this.iParqueClient.POST(
      `v1/entities/${entityId}/permits/holders/${permitHolderId}/changeState`,
      data
    );
  }

  async getPermitHolderEvidence(entityId, permitHolderId, data) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/permits/holders/${permitHolderId}/evidence`,
      data
    );
  }

  async getAll(entityId, driverHash, params = {}) {
    return this.iParqueClient.GET(
      `v2/entities/${entityId}/drivers/${driverHash}/permits/holders`,
      params,
      { Authorization: getTokens().token }
    );
  }

  async getById(entityId, driverHash, permitHolderId, params = {}) {
    return this.iParqueClient.GET(
      `v2/entities/${entityId}/drivers/${driverHash}/permits/holders/${permitHolderId}`,
      params,
      { Authorization: getTokens().token }
    );
  }

  async getCoverage(entityId, driverHash, permitHolderId, params = {}) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/drivers/${driverHash}/permits/holders/${permitHolderId}/coverage`,
      params,
      { Authorization: getTokens().token }
    );
  }

  async getSubscriptions(entityId, driverHash, permitHolderId, params = {}) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/drivers/${driverHash}/permits/holders/${permitHolderId}/subscriptions`,
      params,
      { Authorization: getTokens().token }
    );
  }

  async getSubscriptionById(
    entityId,
    driverHash,
    permitHolderId,
    subscriptionId,
    params = {}
  ) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/drivers/${driverHash}/permits/holders/${permitHolderId}/subscriptions/${subscriptionId}`,
      params,
      { Authorization: getTokens().token }
    );
  }

  async getVehiclesOptions(entityId, driverHash, permitHolderId, params = {}) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/drivers/${driverHash}/permits/holders/${permitHolderId}/vehicles/all`,
      params,
      { Authorization: getTokens().token }
    );
  }

  async getVehicles(entityId, driverHash, permitHolderId, params = {}) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/drivers/${driverHash}/permits/holders/${permitHolderId}/vehicles`,
      params,
      { Authorization: getTokens().token }
    );
  }

  async associateDriver(entityId, driverHash, permitHolderId) {
    return this.iParqueClient.POST(
      `v1/entities/${entityId}/drivers/${driverHash}/permits/${permitHolderId}`,
      {},
      { Authorization: getTokens().token }
    );
  }

  async paySubscriptionWithBalance(entityId, driverHash, subscriptionId, params = {}) {
    return this.iParqueClient.POST(
      `v1/entities/${entityId}/drivers/${driverHash}/subscriptions/${subscriptionId}/balancePayment`,
      params,
      { Authorization: getTokens().token }
    );
  }

  async generateSubscriptionPayment(entityId, driverHash, subscriptionId, params = {}) {
    return this.iParqueClient.POST(
      `v1/entities/${entityId}/drivers/${driverHash}/subscriptions/${subscriptionId}/payments/generate`,
      params,
      { Authorization: getTokens().token }
    );
  }

  async getDocuments(entityId, driverHash, permitHolderId, params = {}) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/drivers/${driverHash}/permits/holders/${permitHolderId}/documents`,
      params,
      { Authorization: getTokens().token }
    );
  }

  async addDocument(entityId, permitHolderId, data) {
    return this.iParqueClient.POST(
      `v1/entities/${entityId}/permits/holders/${permitHolderId}/documents`,
      data
    );
  }

  async deleteDocument(entityId, driverHash, permitHolderId, documentId, params = {}) {
    return this.iParqueClient.DELETE(
      `v1/entities/${entityId}/drivers/${driverHash}/permits/holders/${permitHolderId}/documents/${documentId}`,
      params
    );
  }

  async getHistory(entityId, driverHash, permitHolderId, params = {}) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/drivers/${driverHash}/permits/holders/${permitHolderId}/history`,
      params,
      { Authorization: getTokens().token }
    );
  }

  async getRenewal(entityToken, renewalToken, params = {}) {
    return this.iParqueClient.GET(
      `v1/entities/${entityToken}/permits/holders/renewals/${renewalToken}`,
      params
    );
  }

  async submitRenewal(entityToken, renewalToken, params = {}) {
    return this.iParqueClient.POST(
      `v1/entities/${entityToken}/permits/holders/renewals-submission/${renewalToken}`,
      params
    );
  }

  async getRenewalEvidence(entityToken, renewalToken, params = {}) {
    return this.iParqueClient.GET(
      `v1/entities/${entityToken}/permits/holders/renewal/${renewalToken}/evidence`,
      params
    );
  }

  async getPermitParkingRulesAuthorizations(entityId, params = {}) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/permits/holders/parking-rules/authorizations`,
      params
    );
  }
}
