export const actionTypes = {
  SET_RENEWAL_INFO: "SET_RENEWAL_INFO",
  IS_INITIAL_DATA_LOADING: "IS_INITIAL_DATA_LOADING",
  HAS_ERROR: "HAS_ERROR",
  ADD_DOCUMENT: "ADD_DOCUMENT",
  REMOVE_DOCUMENT: "REMOVE_DOCUMENT",
};

const setRenewalInfo = (state, renewalInfo) => ({
  ...state,
  renewalInfo,
});

const setIsInitialDataLoading = (state, isInitialDataLoading) => {
  return {
    ...state,
    isInitialDataLoading,
  };
};

const setHasError = (state, hasError) => ({
  ...state,
  hasError,
});

const addDocument = (state, { id, designation, file }) => ({
  ...state,
  documents: { ...state.documents, [id]: { id, designation, file } },
});

const removeDocument = (state, id) => {
  const documentsCopy = { ...state.documents };

  delete documentsCopy[id];

  return { ...state, documents: documentsCopy };
};

const actions = {
  [actionTypes.SET_RENEWAL_INFO]: setRenewalInfo,
  [actionTypes.IS_INITIAL_DATA_LOADING]: setIsInitialDataLoading,
  [actionTypes.HAS_ERROR]: setHasError,
  [actionTypes.ADD_DOCUMENT]: addDocument,
  [actionTypes.REMOVE_DOCUMENT]: removeDocument,
};

export default actions;
