import { useAppContext } from "components/AppProvider";
import AdvanceButton from "components/permitHolders/components/AdvanceButton";
import DocumentCard from "components/permitHolders/components/ConfirmationStep/DocumentCard";
import RecaptchaInfo from "domain/public/components/RecaptchaInfo";
import { usePublicEntityContext } from "domain/public/entities/Provider";
import { publicEntitiesRoutes } from "domain/public/entities/routes";
import useGoogleRecaptcha from "hooks/useGoogleRecaptcha";
import { Text, errorToast } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
  getPermitHolderRenewalEvidence,
  saveDocument,
  submitPermitHolderRenewal,
} from "requests/permitHolders";
import styled from "styled-components";
import { routesMapping } from "utils/routes";
import { warningMessage } from "utils/userMessages";
import { usePermitHolderRenewalContext } from "../../Provider";

const ConfirmationStep = () => {
  const { t } = useTranslation();
  const { setIsLoading } = useAppContext();
  const { entity } = usePublicEntityContext();
  const { documents, renewalInfo } = usePermitHolderRenewalContext();
  const { executeGoogleRecaptcha, isGoogleRecaptchaLoaded } = useGoogleRecaptcha();
  const { renewalToken, entityToken } = useParams();
  const history = useHistory();

  const onConfirm = async () => {
    setIsLoading(true);

    const token = await executeGoogleRecaptcha();

    if (!token) {
      setIsLoading(false);
      warningMessage(t("7858") /* Por favor, tenta novamente. */);
      return;
    }

    const result = await submitPermitHolderRenewal(
      entity.id,
      renewalToken,
      {
        recaptchaToken: token,
      },
      { t }
    );

    if (!result) {
      setIsLoading(false);
      return;
    }

    const results = await Promise.all(
      Object.values(documents).map((doc) =>
        saveDocument(
          entity.id,
          renewalInfo.token,
          {
            file: doc.file,
            typeId: doc.id,
          },
          { autoCloseError: false }
        )
      )
    );

    if (results.some((docResult) => !docResult)) {
      errorToast(
        t("3613") /* Erro */,
        t(
          "10528"
        ) /* Foram obtidos alguns erros ao guardar os dados do dístico, por favor contacta o nosso apoio ao cliente */,
        { autoClose: false }
      );

      setIsLoading(false);
      return;
    }

    const { allowRegistry, associated } = result;

    const evidenceResponse = await getPermitHolderRenewalEvidence(
      entity.id,
      renewalToken,
      {},
      { t }
    );

    history.push(
      `${routesMapping.publicEntities}/${publicEntitiesRoutes.permitHoldersRenewalConfirmation}`
        .replace(":entityToken", entityToken)
        .replace(":renewalToken", renewalToken),
      {
        evidenceUrl: evidenceResponse?.url,
        allowRegistry,
        associated,
        renewalToken,
      }
    );

    setIsLoading(false);
  };

  return (
    <Container>
      <div>
        <SectionTitle variant="body10" color="quintenary" className="mt-20">
          {t("4297") /* Documentos */}
        </SectionTitle>
        {Object.values(documents).map((doc) => (
          <DocumentCard key={doc.designation} {...doc} />
        ))}
      </div>
      <div className="mt-50">
        <RecaptchaInfo />
        <AdvanceButton
          type="button"
          lastStep
          onClick={onConfirm}
          disabled={!isGoogleRecaptchaLoaded}
        />
      </div>
    </Container>
  );
};

export default ConfirmationStep;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;

  .form-field {
    min-height: 60px;
  }
`;

const SectionTitle = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  margin-bottom: 5px;
`;
