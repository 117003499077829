import { useStepsContext } from "domain/public/components/StepsProvider";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { steps } from "../helper";

const useSubtitle = () => {
  const { currentStep } = useStepsContext();
  const { t } = useTranslation();

  const subtitle = useMemo(
    () =>
      ({
        [steps.info]: undefined,
        [steps.documents]: t("12541") /* Adiciona os documentos indicados */,
        [steps.confirmation]: t("12542") /* Verifica os dados introduzidos */,
      }[currentStep?.index] || ""),
    [currentStep, t]
  );

  return subtitle;
};

export default useSubtitle;
