import React from "react";
import { useTranslation } from "react-i18next";
import Body from "./components/Body";

const RegisteredWithoutAssociatedAccountStatus = () => {
  const { t } = useTranslation();

  return (
    <Body color="quintenary" className="mt-10" variant="body5">
      {
        t(
          "12815"
        ) /* Como já tem conta no iParque Driver, irá receber um email para associar o dístico à sua conta. */
      }
    </Body>
  );
};

export default RegisteredWithoutAssociatedAccountStatus;
